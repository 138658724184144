// Mis parciales.
// https://www.udemy.com/course/advanced-css-and-scss/learn/lecture/8274478#overview

@import "/src/app/scss/animations";
@import "/src/app/scss/base";
@import "/src/app/scss/functions";
@import "/src/app/scss/mixins";
@import "/src/app/scss/typography";
@import "/src/app/scss/utilities";
@import "/src/app/scss/variables";

@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../node_modules/ag-grid-community/src/styles/ag-theme-balham/sass/legacy/ag-theme-balham-v22-compat.scss";

.OCM-app-root {
	width: 98vw;
	min-height: 96vh;
	display: grid;
	grid-template-rows: auto auto auto;
}

.OCM-navbarFooter {
	a {
		text-decoration: none;
		color: white;
	}
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 40px;
	background-color: map-get($OCM-colors, navBarBackground);
	&--brand {
		display: flex;
		justify-content: space-between;
		&--logo {
			@include OCM-logo;
			background: url("./assets/img/logoOCMyellow.svg") no-repeat center center;
		}
		&--text {
			@include OCM-text;
			font-size: 12px;
		}
	}
	&--title {
		@include OCM-text;
		@include fluid-type($min_width, $max_width, $min_font, $max_font);
	}
	&--rrss {
		display: flex;
		flex-direction: row;
		.logoFacebook {
			@include OCM-logo;
			background: url("./assets/img/facebook.svg") no-repeat center center;
		}
		.logoTwitter {
			@include OCM-logo;
			background: url("./assets/img/twitter.svg") no-repeat center center;
		}
	}
	&--homeEmpty {
		display: flex;
		flex-direction: row;
		width: 246px;
	}
	&--home {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		width: 246px;
		.logoHome {
			@include OCM-logo;
			background: url("./assets/img/home.svg") no-repeat center center;
			margin-right: 20px;
		}
	}
	&--mail {
		@include OCM-text;
		text-decoration-style: none;
		margin-right: 20px;
		a:hover {
			transform: scale(1.2);
		}
	}
}

// Se utiliza en indice e indice-graficos
.OCM-button {
	box-shadow: inset 0px 1px 0px 0px #97c4fe;
	background: linear-gradient(to bottom, #3d94f6 5%, #1e62d0 100%);
	background-color: #3d94f6;
	border-radius: 6px;
	border: 1px solid #337fed;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: #ffffff;
	height: 75px;
	font-family: Arial;
	font-size: 24px;
	font-weight: bold;
	padding: 6px 24px;
	text-decoration: none;
	text-shadow: 0px 1px 0px #1570cd;
	&:hover {
		cursor: grab;
		transform: scale(1.2);
		box-shadow: 0 5px 15px rgba(238, 5, 24, 0.932);
	}
}

// #region @media screen
@media screen and (max-width: 1900px) {
	.ag-cell-value {
		font-size: 12px;
	}

	#home {
		font-size: 16px;
	}

	#logo {
		margin-top: 2px;
		width: 173px;
		height: 37px;
	}
	.textoLogo {
		margin-left: 2px;
		font-size: 20px;
	}

	.titulo {
		font-weight: 700 !important;
		margin-top: 20px;
		color: #f3f4f5;
		font-family: var(--fuente-principal);
		font-size: 28px;
		text-shadow: 2px 2px 2px rgb(10, 2, 2);
	}
}

@media screen and (max-width: 2000px) {
	.ag-header-cell-text {
		font-size: 12px;
	}

	.ag-cell-value {
		font-size: 12px;
	}

	#home {
		font-size: 14px;
	}

	#logo {
		margin-top: 2px;
		width: 173px;
		height: 37px;
	}
	.textoLogo {
		margin-left: 2px;
		font-size: 16px;
	}

	.titulo {
		font-weight: 700 !important;
		margin-top: 20px;
		color: #f3f4f5;
		font-family: var(--fuente-principal);
		font-size: 24px;
		text-shadow: 2px 2px 2px rgb(10, 2, 2);
	}
}

@media screen and (max-width: 1200px) {
	.ag-header-cell-text {
		font-size: 10px;
	}

	.ag-cell-value {
		font-size: 12px;
	}

	#home {
		font-size: 14px;
	}

	#logo {
		margin-top: 2px;
		width: 173px;
		height: 37px;
	}
	.textoLogo {
		margin-left: 2px;
		font-size: 16px;
	}

	.titulo {
		font-weight: 700 !important;
		margin-top: 20px;
		color: #f3f4f5;
		font-family: var(--fuente-principal);
		font-size: 24px;
		text-shadow: 2px 2px 2px rgb(10, 2, 2);
	}
}

@media screen and (max-width: 992px) {
	// MD Samsung Note apaisado
	.ag-header-cell-text {
		font-size: 12px;
	}

	.ag-cell-value {
		font-size: 10px;
	}

	#home {
		font-size: 12px;
	}

	#logo {
		margin-top: 2px;
		width: 173px;
		height: 37px;
	}
	.textoLogo {
		margin-left: 1px;
		font-size: 8px;
	}

	.titulo {
		font-weight: 600 !important;
		margin-top: 20px;
		color: #f3f4f5;
		font-family: var(--fuente-principal);
		font-size: 20px;
		text-shadow: 2px 2px 2px rgb(10, 2, 2);
	}
}

@media screen and (max-width: 750px) {
	.ag-header-cell-text {
		font-size: 9px;
	}

	.ag-cell-value {
		font-size: 8px;
	}

	#home {
		font-size: 10px;
	}

	#logo {
		margin-top: 2px;
		width: 173px;
		height: 37px;
	}
	.textoLogo {
		margin-left: 2px;
		font-size: 8px;
	}

	.titulo {
		font-weight: 500 !important;
		margin-top: 20px;
		color: #f3f4f5;
		font-family: var(--fuente-principal);
		font-size: 16px;
		text-shadow: 2px 2px 2px rgb(10, 2, 2);
	}
}

@media screen and (max-width: 576px) {
	.ag-header-cell-text {
		font-size: 9px;
	}

	.ag-cell-value {
		font-size: 5px;
	}

	#home {
		font-size: 8px;
	}

	#logo {
		margin-top: 2px;
		width: 173px;
		height: 37px;
	}
	.textoLogo {
		margin-left: 2px;
		font-size: 8px;
	}

	.titulo {
		font-weight: 400 !important;
		margin-top: 20px;
		color: #f3f4f5;
		font-family: var(--fuente-principal);
		font-size: 12px;
		text-shadow: 2px 2px 2px rgb(10, 2, 2);
	}
}
// #endregion
/******** END @media screen *****************/

// #region AG Grid
.ag-header-group-cell {
	font-size: 20px;
	color: red;
}

.ag-header-cell {
	width: 20px;
	// transform: rotate(90deg);
	// margin-top: 10px;
	/*Since we are rotating a span*/
	display: inline-block;
}

// Sobreescribe lo definido en  /node_modules\ag-grid-community\src\styles\ag-grid.scss :365
// .ag-header-cell-label {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   color: green;
//   font-family: Arial;
//   font-size: 14px;
//   font-weight: bold;
//   /*Necessary to allow for text to grow vertically*/
//   height: 100%;
//   padding:0 !important;
// }

.ag-header-cell-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: green;
	font-family: Arial;
	font-weight: bold;
	font-size: 12px;
}

.ag-cell-label-container {
	/*Necessary to allow for text to grow vertically*/
	height: 100%;
}

.ag-pivot-off {
	color: #8a6d3b;
}

.ag-pivot-on {
	color: #1b6d85;
	font-weight: bold;
}

.ag-pivot-off {
	font-size: 50px;
	color: red;
}

.ag-pivot-on {
	font-size: 25px;
	color: green;
}
.ag-floating-filter-body input {
	height: 49px;
}

.ag-floating-filter-button {
	margin-top: -49px;
}

.ag-floating-filter-button button {
	height: 49px;
}

.ag-floating-filter-body input {
	font-size: 15px;
	font-weight: bold;
}

.ag-row-group-leaf-indent > .ag-group-value {
	text-align-last: right;
	width: 100%;
}

.ag-cell-value {
	// overflow: hidden;
	text-overflow: ellipsis;
	color: black;
}
// #endregion
/******** END ag-grid *****************/
